// ** Third Party Components
import { Badge, Col, CustomInput } from 'reactstrap'

// ** Table Server Side Column
export const transportationColumnStyles = [
  {
    selector: 'order_id',
    cell: row => {
      return (
        <Col>
          <Badge color={'light-secondary'}>
            {row.order_id}
          </Badge>
          <div className='font-small-2 text-muted'>{row.product}</div>
        </Col>
      )
    }
  },
  {
    selector: 'container_numbers',
    cell: row => {
      return (
        <Col>
          {row.container_numbers.join('; ')}
        </Col>
      )
    }
  },
  {
    selector: 'release',
    cell: row => {
      return (
        <Col>
          <CustomInput type='checkbox' checked={row.release} readOnly className='custom-control-Primary' id='release'/>
        </Col>
      )
    }
  }
]

const transportationTableColumns = [
  {
    name: '№ Заявки/Спецификации',
    selector: 'order_id',
    sortable: true,
    minWidth: '270px',
    maxWidth: '270px'
  },
  {
    name: 'Отправитель',
    selector: 'sender',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Тип контейнера',
    selector: 'container_type',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: '№ контейнера',
    selector: 'container_numbers',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Порт отправления',
    selector: 'port_of_departure',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Дата выхода судна из порта расчетное',
    selector: 'vessel_departure_date_estimated',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Дата выхода судна из порта фактическое',
    selector: 'vessel_departure_date_actual',
    sortable: true,
    maxWidth: '250px',
    minWidth: '250px'
  },
  {
    name: 'Порт назначения',
    selector: 'port_of_arrival',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Дата прихода судна в порт расчетное',
    selector: 'vessel_arrival_date_estimated',
    sortable: true,
    maxWidth: '250px',
    minWidth: '250px'
  },
  {
    name: 'Дата прихода судна в порт фактическое',
    selector: 'vessel_arrival_date_actual',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Дата подачи ДТ',
    selector: 'cargo_customs_declaration_submission_date',
    sortable: true,
    minWidth: '150px',
    maxWidth: '150px'
  },
  {
    name: 'Дата выпуска ДТ',
    selector: 'cargo_customs_declaration_issue_date',
    sortable: true,
    minWidth: '150px',
    maxWidth: '150px'
  },
  {
    name: 'Релиз',
    selector: 'release',
    sortable: true,
    minWidth: '100px',
    maxWidth: '100px'
  },
  {
    name: 'Назначение',
    selector: 'destination',
    sortable: true,
    minWidth: '150px',
    maxWidth: '150px'
  },
  {
    name: 'Дата прибытия на склад',
    selector: 'warehouse_arrival_date',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  }
]

export default transportationTableColumns
