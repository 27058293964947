import { handleError } from '@utils'

// ** Initial State
const initialState = {
  userData: null,
  errors: null,
  changed: false
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_GET_DATA':
      return {
        ...state,
        userData: action.userData
      }
    case 'USER_CHANGE_DATA':
      return {
        ...state,
        userData: action.data,
        changed: true
      }
    case 'USER_CHANGE_DATA_ERROR':
      return {
        ...state,
        errors: handleError(action.errors),
        changed: false
      }
    default:
      return { ...state }
  }
}
export default users