// ** Initial State
const initialState = {
  data: [],
  success: false
}

const PagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PAGE':
      return {
        ...state,
        data: action.data.data,
        success: action.data.result
      }
    default:
      return state
  }
}

export default PagesReducer
