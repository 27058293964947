// ** Third Party Components
import { Badge } from 'reactstrap'

// ** Table Server Side Column
export const agreementsColumnStyles = [
  {
    selector: 'id',
    cell: row => {
      return (
        <Badge color={'light-secondary'}>
          {row.id}
        </Badge>
      )
    }
  }
]

const agreementsTableColumns = [
  {
    name: '№ ID',
    selector: 'id',
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px'
  },
  {
    name: 'Номер договора',
    selector: 'agreement_number',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Дата',
    selector: 'date',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Компания',
    selector: 'company',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Контрагент',
    selector: 'counterparty',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  }
]

export default agreementsTableColumns
