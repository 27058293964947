// ** Third Party Components
import { Badge, CustomInput } from 'reactstrap'

const statusColors = {
  'в пути': 'light-info',
  задерживается: 'light-warning',
  'на подписании': 'light-warning',
  доставлено: 'success',
  закрыто: 'light-secondary'
}

// ** Table Server Side Column
export const applicationsColumnStyles = [
  {
    selector: 'id',
    cell: row => {
      return (
        <Badge color={'light-secondary'}>
          {row.id}
        </Badge>
      )
    }
  },
  {
    selector: 'shipment',
    cell: row => {
      return (
        <CustomInput type='checkbox' checked={row.shipment} readOnly className='custom-control-Primary' id='shipment'/>
      )
    }
  },
  {
    selector: 'insurance',
    cell: row => {
      return (
        <CustomInput type='checkbox' checked={row.insurance} readOnly className='custom-control-Primary' id='insurance'/>
      )
    }
  },
  {
    selector: 'customs_clearance',
    cell: row => {
      return (
        <CustomInput type='checkbox' checked={row.customs_clearance} readOnly className='custom-control-Primary' id='customs_clearance'/>
      )
    }
  },
  {
    selector: 'certification',
    cell: row => {
      return (
        <CustomInput type='checkbox' checked={row.certification} readOnly className='custom-control-Primary' id='certification'/>
      )
    }
  },
  {
    selector: 'status',
    cell: row => {
      return (
        <Badge pill color={statusColors[row.status.toLowerCase()]} className='badge-glow' style={{ fontSize: '0.9rem' }}>
          {row.status}
        </Badge>
      )
    }
  }
]

const applicationsTableColumns = [
  {
    name: '№ ID',
    selector: 'id',
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px'
  },
  {
    name: 'Договор',
    selector: 'agreement_data',
    sortable: true,
    minWidth: '450px',
    maxWidth: '450px'
  },
  {
    name: 'Номер спецификации',
    selector: 'specification_number',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Дата',
    selector: 'specification_date',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Товар',
    selector: 'product',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Доставка',
    selector: 'shipment',
    center: true,
    sortable: true,
    minWidth: '150px',
    maxWidth: '150px'
  },
  {
    name: 'Страхование',
    selector: 'insurance',
    center: true,
    sortable: true,
    minWidth: '150px',
    maxWidth: '150px'
  },
  {
    name: 'ТО',
    selector: 'customs_clearance',
    center: true,
    sortable: true,
    minWidth: '100px',
    maxWidth: '100px'
  },
  {
    name: 'Сертификация',
    selector: 'certification',
    center: true,
    sortable: true,
    minWidth: '200px',
    maxWidth: '200px'
  },
  {
    name: 'Статус',
    selector: 'status',
    center: true,
    sortable: true,
    minWidth: '150px',
    maxWidth: '150px'
  },
  {
    name: 'Менеджер',
    selector: 'manager',
    sortable: true,
    minWidth: '200px',
    maxWidth: '200px'
  },
  {
    name: 'Дата закрытия',
    selector: 'closing_date',
    sortable: true,
    minWidth: '150px',
    maxWidth: '150px'
  }
]

export default applicationsTableColumns
