// ** Third Party Components
import { Badge, CustomInput } from 'reactstrap'

// ** Table Server Side Column
export const productsColumnStyles = [
  {
    selector: 'id',
    cell: row => {
      return (
        <Badge color={'light-secondary'}>
          {row.id}
        </Badge>
      )
    }
  }
]

const productsTableColumns = [
  {
    name: '№ ID',
    selector: 'id',
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px'
  },
  {
    name: 'Договор',
    selector: 'agreement',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Заявка',
    selector: 'application',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Наименование',
    selector: 'name',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Ед. изм.',
    selector: 'unit',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Количество',
    selector: 'amount',
    center: true,
    sortable: true,
    minWidth: '150px',
    maxWidth: '150px'
  },
  {
    name: 'Сумма',
    selector: 'sum',
    center: true,
    sortable: true,
    minWidth: '150px',
    maxWidth: '150px'
  },
  {
    name: 'Валюта',
    selector: 'currency',
    center: true,
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Поставщик',
    selector: 'provider',
    center: true,
    sortable: true,
    minWidth: '200px',
    maxWidth: '200px'
  }
]

export default productsTableColumns
