// ** Third Party Components
import { Badge } from 'reactstrap'

// ** Table Server Side Column
export const companiesColumnStyles = [
  {
    selector: 'id',
    cell: row => {
      return (
        <Badge color={'light-secondary'}>
          {row.id}
        </Badge>
      )
    }
  }
]

const companiesTableColumns = [
  {
    name: '№ ID',
    selector: 'id',
    sortable: true,
    minWidth: '100px',
    maxWidth: '100px'
  },
  {
    name: 'ИНН',
    selector: 'INN',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'КПП',
    selector: 'KPP',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Наименование',
    selector: 'name',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Руководитель',
    selector: 'supervisor',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Адрес',
    selector: 'address',
    sortable: true,
    maxWidth: '400px',
    minWidth: '400px'
  }
]

export default companiesTableColumns
