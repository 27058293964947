// ** Initial State
const initialState = {
  groupValue: null,
  personValue: null
}

const selectReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_JURIDICAL_SELECT_VALUES':
      return { ...state, groupValue: action.groupValue, personValue: action.personValue }
    default:
      return state
  }
}

export default selectReducer
