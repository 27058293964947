// ** Third Party Components
import { Badge } from 'reactstrap'

const statusColors = {
  'в пути': 'light-info',
  задерживается: 'light-warning',
  'на подписании': 'light-warning',
  доставлено: 'success',
  закрыто: 'light-secondary',
  оплачено: 'light-success',
  'не оплачено': 'danger'
}

// ** Table Server Side Column
export const financesColumnStyles = [
  {
    selector: 'id',
    cell: row => {
      return (
        <Badge color={'light-secondary'}>
          {row.id}
        </Badge>
      )
    }
  },
  {
    selector: 'status',
    cell: row => {
      return (
        <Badge pill color={statusColors[row.status.toLowerCase()]} className='badge-glow' style={{ fontSize: '0.9rem' }}>
          {row.status}
        </Badge>
      )
    }
  }
]

const financesTableColumns = [
  {
    name: '№ ID',
    selector: 'id',
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px'
  },
  {
    name: 'Договор',
    selector: 'agreement',
    sortable: true,
    minWidth: '450px',
    maxWidth: '450px'
  },
  {
    name: 'Заявка',
    selector: 'application',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Номер',
    selector: 'number',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Дата',
    selector: 'date',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Сумма',
    selector: 'sum',
    sortable: true,
    minWidth: '250px',
    maxWidth: '250px'
  },
  {
    name: 'Валюта',
    selector: 'currency',
    center: true,
    sortable: true,
    minWidth: '200px',
    maxWidth: '200px'
  },
  {
    name: 'Статус',
    selector: 'status',
    center: true,
    sortable: true,
    minWidth: '150px',
    maxWidth: '150px'
  }
]

export default financesTableColumns
