import { handleError } from '@utils'

// ** Initial State
const initialState = {
  employeeData: null,
  errors: null,
  changed: false,
  created: false
}

const employees = (state = initialState, action) => {
  switch (action.type) {
    case 'EMPLOYEE_CREATE':
      return {
        ...state,
        created: true
      }
    case 'EMPLOYEES_GET_DATA':
      return {
        ...state,
        employeeData: action.employeeData
      }
    case 'EMPLOYEE_CHANGE_DATA':
      return {
        ...state,
        employeeData: action.data,
        changed: true
      }
    case 'EMPLOYEE_ERROR':
      return {
        ...state,
        errors: handleError(action.errors)
      }
    default:
      return { ...state }
  }
}
export default employees