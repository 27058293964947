// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  columns: [],
  title: '',
  extraParameters: [],
  progressPending: false,
  error: ''
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'DATATABLE_LOADING':
      return {
        ...state,
        columns: [],
        progressPending: true,
        error: false,
        title: ''
      }
    case 'DATATABLE_GET_DATA':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        columns: action.columns,
        title: action.title,
        extraParameters: action.extraParameters,
        progressPending: false,
        error: false,
        params: action.params
      }
    case 'DATATABLE_ERROR':
      return {
        ...state,
        allData: [],
        data: [],
        total: 1,
        columns: [],
        title: '',
        extraParameters: [],
        progressPending: false,
        error: true
      }
    default:
      return state
  }
}

export default DataTablesReducer
